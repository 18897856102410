import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Pagination,
  Button,
} from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import { Link } from "react-router-dom";
import AllServices from "../All Services Data/AllServicesData";

// Add flowing border animation
const borderFlow = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const ProductCard = styled(Box)(({ theme }) => ({
  background: "white",
  borderRadius: "16px",
  padding: "24px",

  [theme.breakpoints.down("sm")]: {
    height: "auto",
  },
  [theme.breakpoints.up("sm")]: {
    height: "400px",
  },
  width: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  transition: "all 0.3s ease",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
  "& .more-info-button": {
    border: "2px solid #0062ff",
    color: "#0062ff",
    "&:hover": {
      background: "#ffffff",
      borderColor: "#ffffff",
      color: "#0062ff",
    },
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: -2,
    left: -2,
    right: -2,
    bottom: -2,
    background: "linear-gradient(90deg, #0062ff, #00d5ff, #0062ff, #00d5ff)",
    backgroundSize: "300% 300%",
    animation: `${borderFlow} 4s linear infinite`,
    borderRadius: "18px",
    zIndex: -1,
    opacity: 0,
    transition: "opacity 0.3s ease",
  },
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.12)",
    background:
      "linear-gradient(135deg, rgba(0, 98, 255, 0.95), rgba(0, 213, 255, 0.95))",
    "&::before": {
      opacity: 1,
    },
    "& .MuiTypography-root": {
      color: "#ffffff",
    },
    "& .company-name": {
      color: "rgba(255, 255, 255, 0.9)",
    },
    "& .rating-text": {
      color: "rgba(255, 255, 255, 0.9)",
    },
    "& .more-info-button": {
      border: "2px solid #ffffff",
      color: "#ffffff",
      "&:hover": {
        background: "#ffffff",
        borderColor: "#ffffff",
        color: "#0062ff",
      },
    },
  },
}));

const CardWrapper = styled(Box)({
  padding: "2px",
  borderRadius: "18px",
  background: "white",
  height: "calc(100% - 4px)",
  "& a": {
    textDecoration: "none",
    height: "100%",
    display: "block",
  },
});

const ImageContainer = styled(Box)(({ theme }) => ({
  borderRadius: "12px",
  marginBottom: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    height: "auto",
  },
  [theme.breakpoints.up("sm")]: {
    height: "120px",
  },
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transition: "transform 0.3s ease",
  },
  "&:hover img": {
    transform: "scale(1.05)",
  },
}));

const ContentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "calc(100% - 136px)",
});

const Badge = styled(Box)({
  position: "absolute",
  top: "24px",
  left: "24px",
  background: "linear-gradient(135deg, #FF9800, #FF5722)",
  color: "white",
  padding: "6px 16px",
  borderRadius: "30px",
  fontSize: "13px",
  fontWeight: "600",
  letterSpacing: "0.3px",
  boxShadow: "0 4px 12px rgba(255, 152, 0, 0.3)",
});

const MoreInfoButton = styled(Button)({
  width: "100%",
  padding: "12px",
  background: "transparent",
  borderRadius: "30px",
  fontSize: "14px",
  fontWeight: "600",
  textTransform: "none",
  transition: "all 0.3s ease",
});

const FeatureBadge = styled(Box)({
  background: "linear-gradient(135deg, #E3F2FD, #BBDEFB)",
  color: "#1976D2",
  padding: "6px 12px",
  borderRadius: "8px",
  fontSize: "12px",
  fontWeight: "600",
  display: "inline-flex",
  alignItems: "center",
  gap: "4px",
  letterSpacing: "0.2px",
});

const TitleTypography = styled(Typography)({
  fontSize: "16px",
  fontWeight: 600,
  color: "#1A237E",
  lineHeight: 1.2,
  height: "40px",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  marginBottom: "8px",
  transition: "color 0.3s ease",
});

const CompanyTypography = styled(Typography)({
  color: "#64748B",
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "16px",
  height: "20px",
  transition: "color 0.3s ease",
});

const RatingContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  marginBottom: "16px",
  height: "24px",
});

const ServicesSingleGrid = () => {
  const blogsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * blogsPerPage;
  const endIndex = startIndex + blogsPerPage;
  const currentBlogs = AllServices.slice(startIndex, endIndex);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <Container maxWidth="xl" sx={{ py: 6 }}>
      <Grid container spacing={3}>
        {currentBlogs.map((product, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <CardWrapper>
              <Link to={`/all-services/${product.id}`}>
                <ProductCard>
                  {product.isNewFeature && <Badge>Best Seller</Badge>}

                  <ImageContainer>
                    <img
                      src={product.imageSrc}
                      alt={product.title}
                      className="img-fluid"
                      style={{ borderRadius: "10px" }}
                    />
                  </ImageContainer>

                  <ContentContainer>
                    <Box>
                      <TitleTypography>{product.productName}</TitleTypography>

                      <CompanyTypography className="company-name">
                        By {product.productCompany}
                      </CompanyTypography>

                      <RatingContainer>
                        <Box sx={{ display: "flex", gap: 0.5 }}>
                          {[...Array(5)].map((_, i) => (
                            <Box
                              key={i}
                              component="span"
                              className={
                                i < product.rating
                                  ? "rating-stars"
                                  : "empty-stars"
                              }
                              sx={{
                                color:
                                  i < product.rating ? "#FFA000" : "#E0E0E0",
                                fontSize: "16px",
                                transition: "color 0.3s ease",
                              }}
                            >
                              ★
                            </Box>
                          ))}
                        </Box>
                        <Typography
                          className="rating-text"
                          sx={{
                            color: "#64748B",
                            fontSize: "13px",
                            transition: "color 0.3s ease",
                          }}
                        >
                          ({product.rating}.0)
                        </Typography>
                      </RatingContainer>

                      {product.isNewFeature && (
                        <FeatureBadge
                          sx={{
                            height: "28px",
                            mb: 2,
                            background: "rgba(255, 255, 255, 0.2)",
                            color: "#ffffff",
                          }}
                        >
                          <span>✨</span>
                          New Feature
                        </FeatureBadge>
                      )}
                    </Box>

                    <Box sx={{ mt: "auto" }}>
                      <MoreInfoButton className="more-info-button">
                        More Info
                      </MoreInfoButton>
                    </Box>
                  </ContentContainer>
                </ProductCard>
              </Link>
            </CardWrapper>
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 4,
        }}
      >
        <Pagination
          count={Math.ceil(AllServices.length / blogsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
          showFirstButton
          showLastButton
          sx={{
            "& .MuiPaginationItem-root": {
              fontSize: "1rem",
              "&.Mui-selected": {
                background: "linear-gradient(135deg, #FF9800, #FF5722)",
                color: "white",
                "&:hover": {
                  background: "linear-gradient(135deg, #FF9800, #FF5722)",
                },
              },
            },
          }}
        />
      </Box>
    </Container>
  );
};

export default ServicesSingleGrid;
