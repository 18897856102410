import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Container,
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Breadcrumbs,
  Chip,
  Button,
  Grid,
  alpha,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from "@mui/icons-material/Article";
import blogsData from "../Components/HomePageBlog/blog";
import { keyframes } from "@mui/system";
import { motion } from "framer-motion";

const float = keyframes`
  0% { transform: translateY(0px) }
  50% { transform: translateY(-10px) }
  100% { transform: translateY(0px) }
`;

// Enhanced styled components
const StyledBlogCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: 24,
  overflow: "hidden",
  transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
  background: "rgba(255, 255, 255, 0.95)",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
  transform: "perspective(1000px) rotateX(0deg)",
  "&:hover": {
    transform: "perspective(1000px) rotateX(2deg) translateY(-8px)",
    boxShadow: "0 24px 48px rgba(0, 0, 0, 0.2)",
    "& .card-image": {
      transform: "scale(1.1) translateY(-4px)",
    },
    "& .card-content": {
      transform: "translateY(-4px)",
    },
    "& .read-more-btn": {
      transform: "translateX(8px)",
      color: theme.palette.primary.main,
    },
  },
}));

const GlassButton = styled(Button)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255, 255, 255, 0.2)",
  borderRadius: "16px",
  padding: "12px 24px",
  color: "white",
  fontWeight: 600,
  textTransform: "none",
  transition: "all 0.3s ease",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.2)",
    transform: "translateY(-2px)",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  },
}));

const PaginationButton = styled(Button)(({ theme }) => ({
  minWidth: 40,
  height: 40,
  margin: "0 4px",
  borderRadius: 8,
  fontWeight: 600,
  "&.active": {
    background: "linear-gradient(45deg, #1a237e, #1976d2)",
    color: "white",
    "&:hover": {
      background: "linear-gradient(45deg, #1a237e, #1976d2)",
    },
  },
}));

const Blogs = () => {
  const blogsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * blogsPerPage;
  const endIndex = startIndex + blogsPerPage;
  const currentBlogs = blogsData.slice(startIndex, endIndex);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        minHeight: "100vh",
        mt: { xs: "56px", sm: "60px" },
      }}
    >
      {/* Hero Section */}
      <Box
        sx={{
          background:
            "linear-gradient(135deg, #1a237e 0%, #1976d2 50%, #0097a7 100%)",
          color: "white",
          py: 12,
          mb: 6,
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "radial-gradient(circle at 50% 50%, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)",
          },
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 800,
                mb: 3,
                fontSize: { xs: "2.5rem", md: "4rem" },
                textShadow: "0 4px 12px rgba(0,0,0,0.2)",
                background: "linear-gradient(135deg, #fff 0%, #e3f2fd 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Our Blog
            </Typography>

            <Breadcrumbs
              separator={
                <NavigateNextIcon
                  sx={{
                    color: "rgba(255,255,255,0.8)",
                    animation: `${float} 2s ease-in-out infinite`,
                  }}
                />
              }
              sx={{
                "& a": {
                  color: "rgba(255,255,255,0.8)",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  transition: "all 0.3s ease",
                  "&:hover": {
                    color: "white",
                    transform: "translateY(-2px)",
                  },
                },
              }}
            >
              <Link to="/">
                <HomeIcon
                  sx={{
                    fontSize: 24,
                    filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.2))",
                  }}
                />
                Home
              </Link>
              <Link to="/blogs">
                <ArticleIcon
                  sx={{
                    fontSize: 24,
                    filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.2))",
                  }}
                />
                Blog
              </Link>
            </Breadcrumbs>
          </motion.div>
        </Container>
      </Box>

      {/* Blog Grid */}
      <Container maxWidth="lg" sx={{ mb: 8 }}>
        <Grid container spacing={4}>
          {currentBlogs.map((blog) => (
            <Grid item xs={12} sm={6} md={4} key={blog.id}>
              <StyledBlogCard>
                <Box
                  sx={{ position: "relative", pt: "60%", overflow: "hidden" }}
                >
                  <CardMedia
                    component="img"
                    image={blog.imageSrc}
                    alt={blog.title}
                    className="card-image"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      transition: "transform 0.6s cubic-bezier(0.4, 0, 0.2, 1)",
                    }}
                  />
                  <Chip
                    label={blog.category}
                    sx={{
                      position: "absolute",
                      top: 16,
                      left: 16,
                      bgcolor: alpha("#fff", 0.95),
                      fontWeight: 600,
                      backdropFilter: "blur(4px)",
                    }}
                  />
                </Box>

                <CardContent sx={{ p: 3, flexGrow: 1 }}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 700,
                      mb: 2,
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      lineHeight: 1.4,
                    }}
                  >
                    {blog.title}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      mb: 3,
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      lineHeight: 1.6,
                    }}
                  >
                    {blog.intro}
                  </Typography>

                  <Link
                    to={`/blog/${blog.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      className="read-more-btn"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        color: "text.secondary",
                        fontWeight: 600,
                        transition: "all 0.4s ease",
                        "& .arrow-icon": {
                          transition: "transform 0.4s ease",
                          filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.1))",
                        },
                        "&:hover .arrow-icon": {
                          transform: "translateX(4px) scale(1.1)",
                        },
                      }}
                    >
                      Read More
                      <ArrowForwardIcon
                        className="arrow-icon"
                        sx={{ fontSize: 20 }}
                      />
                    </Box>
                  </Link>
                </CardContent>
              </StyledBlogCard>
            </Grid>
          ))}
        </Grid>

        {/* Pagination */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            mt: 6,
            mb: 8,
          }}
        >
          {currentPage > 1 && (
            <GlassButton
              onClick={() => setCurrentPage(currentPage - 1)}
              startIcon={
                <NavigateNextIcon sx={{ transform: "rotate(180deg)" }} />
              }
              sx={{
                bgcolor: "primary.main",
                "&:hover": {
                  bgcolor: "primary.dark",
                },
              }}
            >
              Prev
            </GlassButton>
          )}

          {Array.from({
            length: Math.ceil(blogsData.length / blogsPerPage),
          }).map((_, index) => (
            <GlassButton
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              sx={{
                minWidth: "44px",
                height: "44px",
                p: 0,
                bgcolor:
                  currentPage === index + 1 ? "primary.main" : "transparent",
                "&:hover": {
                  bgcolor:
                    currentPage === index + 1
                      ? "primary.dark"
                      : "rgba(255, 255, 255, 0.3)",
                },
              }}
            >
              {index + 1}
            </GlassButton>
          ))}

          {currentPage < Math.ceil(blogsData.length / blogsPerPage) && (
            <GlassButton
              onClick={() => setCurrentPage(currentPage + 1)}
              endIcon={<NavigateNextIcon />}
              sx={{
                bgcolor: "primary.main",
                "&:hover": {
                  bgcolor: "primary.dark",
                },
              }}
            >
              Next
            </GlassButton>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Blogs;
